<template>
    <div class="container">
        <div class="row py-2">
            <div class="col-md-2">
                <div class="ward-img-box">

                </div>
            </div>
            <div class="col-md-7  d-flex align-items-center">
                <span class="py-2">Longest Names</span>
            </div>
            <div class="col-md-3 d-flex align-items-center">
                <span class="py-2">3000</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style scoped>
    .ward-img-box {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid;
    }
</style>